import React, { FC } from 'react';
import MuiField, {
  TextFieldProps as MuiFieldProps,
} from '@material-ui/core/TextField';

export type TextFieldProps = MuiFieldProps;

export const TextField: FC<TextFieldProps> = props => {
  return <MuiField margin={'normal'} variant={'outlined' as any} {...props} />;
};
