import React, { FC } from 'react';
import { CardNumberElement } from 'react-stripe-elements';

import { TextField, TextFieldProps } from '../../form/textField';
import { StripeInput } from './stripeInput';

export type StripeTextFieldProps = Omit<
  TextFieldProps,
  'onChange' | 'InputProps'
> & {};

export const CardElement: FC<StripeTextFieldProps> = ({
  fullWidth,
  variant,
  ...rest
}) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleElementChange = ({ complete, error }: any) => {
    if (error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage(null);
    }
  };

  const hasError = !!errorMessage;

  return (
    <TextField
      onChange={handleElementChange}
      fullWidth={fullWidth ?? true}
      error={hasError}
      helperText={hasError ? errorMessage ?? 'Invalid' : ''}
      label={'Card Number'}
      variant={variant ?? ('outlined' as any)}
      InputProps={{
        inputProps: {
          component: CardNumberElement,
        },
        inputComponent: StripeInput as any,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  );
};
