import React from 'react';
import { navigate } from 'gatsby';
import { StripeProvider, Elements } from 'react-stripe-elements';
import styled from 'styled-components';

import { Grid, Paper, Typography, NoSsr } from '@material-ui/core';

import SEO from '../components/seo';
import { PaymentCardForm } from '../components/stripe/paymentCardForm';

const ContainerGrid = styled(Grid)`
  height: 100%;
  padding: 8px;
`;

const FormGrid = styled(Grid)`
  width: 40%;
`;

const FormContainer = styled(Paper)`
  padding: 16px;
  height: 100%;
  overflow: auto;
`;

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <ContainerGrid
      container
      spacing={0}
      alignItems={'center'}
      justify={'center'}
    >
      <FormGrid item xs={12} lg={7}>
        <FormContainer>
          <Typography variant={'h5'}>Enter payment information</Typography>
          <NoSsr>
            <StripeProvider apiKey={process.env.GATSBY_STRIPE_PUBLIC_KEY}>
              <Elements>
                <PaymentCardForm
                  onSubmit={body => {
                    navigate(body.url);
                  }}
                />
              </Elements>
            </StripeProvider>
          </NoSsr>
        </FormContainer>
      </FormGrid>
    </ContainerGrid>
  </>
);

export default IndexPage;
