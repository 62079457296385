import React, { FC, useRef } from 'react';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { TextField } from '../formik/textField';
import { ApiClient } from '../../services/apiClient';
import { CardElement } from './inputs/cardElement';
import { CardExpiryElement } from './inputs/cardExpiryElement';
import { CardCVCElement } from './inputs/cardCVCElement';

const PaymentCardValidationSchema = yup.object().shape({
  name: yup.string().required('Required.'),
  email: yup
    .string()
    .email()
    .required('Required.'),
  address1: yup.string().required('Required.'),
  address2: yup.string(),
  city: yup.string().required('Required.'),
  country: yup.string().required('Required.'),
  state: yup.string().required('Required.'),
  postalCode: yup.string().required('Required.'),
});

type PaymentCardFormState = yup.InferType<typeof PaymentCardValidationSchema>;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 16;
  padding: 16px 0;
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const StyledExpiration = styled(CardExpiryElement)({
  marginRight: 16,
});

type PaymentCardFormProps = ReactStripeElements.InjectedStripeProps & {
  onSubmit: (body: { url: string }) => void;
};

const PaymentCardFormComponent: FC<PaymentCardFormProps> = props => {
  const cardElementRef = useRef<ReactStripeElements.HTMLStripeElement | null>(
    null
  );
  const expiryElementRef = useRef<ReactStripeElements.HTMLStripeElement | null>(
    null
  );
  const cvcElementRef = useRef<ReactStripeElements.HTMLStripeElement | null>(
    null
  );

  const resetStripeForm = () => {
    cardElementRef?.current?.clear();
    expiryElementRef?.current?.clear();
    cvcElementRef?.current?.clear();
  };

  return (
    <Formik<PaymentCardFormState>
      initialValues={{
        name: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
      }}
      onSubmit={async (values, actions) => {
        const card = await props.stripe?.createToken({
          address_line1: values.address1,
          address_city: values.city,
          address_country: values.country,
          address_line2: values.address2,
          address_state: values.state,
          address_zip: values.postalCode,
          name: values.name,
          type: 'card',
        });

        const responseBody = await ApiClient.createUser({
          address: {
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            country: values.country,
            postalCode: values.postalCode,
            state: values.state,
          },
          email: values.email,
          fullName: values.name,
          payment: {
            type: 'stripe',
            token: card!.token!.id!,
          },
        });

        actions.resetForm();
        resetStripeForm();
        actions.setSubmitting(false);

        props.onSubmit(responseBody);
      }}
      validationSchema={PaymentCardValidationSchema}
    >
      <StyledForm noValidate={true}>
        <CardElement
          name={'card-element'}
          inputRef={el => (cardElementRef.current = el)}
        />
        <FlexContainer>
          <StyledExpiration
            margin={'none'}
            inputRef={el => (expiryElementRef.current = el)}
          />
          <CardCVCElement
            margin={'none'}
            inputRef={el => (cvcElementRef.current = el)}
          />
        </FlexContainer>
        <TextField
          label={'Name'}
          name={'name'}
          required={true}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={'Email'}
          name={'email'}
          required={true}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={'Address 1'}
          name={'address1'}
          required={true}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={'Address 2'}
          name={'address2'}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={'City'}
          name={'city'}
          required={true}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={'Country'}
          name={'country'}
          required={true}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={'State'}
          name={'state'}
          required={true}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={'Postal code'}
          name={'postalCode'}
          required={true}
          variant={'outlined'}
          margin={'normal'}
          InputLabelProps={{ shrink: true }}
        />
        <Button type={'submit'} variant={'outlined'}>
          Submit
        </Button>
      </StyledForm>
    </Formik>
  );
};

export const PaymentCardForm = injectStripe<PaymentCardFormProps>(
  PaymentCardFormComponent
);
