import React, {
  ComponentType,
  FC,
  RefObject,
  useImperativeHandle,
  useRef,
} from 'react';
import { ReactStripeElements } from 'react-stripe-elements';

type StripeInputProps = {
  component: ComponentType<ReactStripeElements.ElementProps>;
  inputRef: RefObject<{ focus: () => void }>;
};

export const StripeInput: FC<StripeInputProps> = props => {
  const { component: Component, inputRef, ...rest } = props;
  const elementRef = useRef<ReactStripeElements.HTMLStripeElement | null>(null);

  useImperativeHandle(inputRef, () => ({
    clear: () => elementRef.current?.clear?.(),
    focus: () => elementRef.current?.focus,
  }));

  return (
    <Component
      placeholder={'asdfasdf'}
      onReady={element => (elementRef.current = element)}
      {...rest}
    />
  );
};
