import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import {
  TextField as FormTextField,
  TextFieldProps as FormTextFieldProps,
} from '../form/textField';
import { FormControl, FormHelperText } from '@material-ui/core';

export type TextFieldProps = FormTextFieldProps & {
  helperText?: string;

  name: string;
};

export const TextField: FC<TextFieldProps> = ({ helperText, ...props }) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => {
        const hasError = form.touched[props.name!]
          ? !!form.errors[props.name]
          : false;

        return (
          <FormControl>
            <FormTextField
              {...props}
              {...field}
              id={props.id ?? props.name}
              error={hasError}
            />
            <FormHelperText error={hasError}>
              {form.touched[props.name!]
                ? form.errors[props.name]
                : helperText ?? ''}
            </FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};
